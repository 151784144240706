import { useRecipeLandingQuery } from "@api";
import CategoryMenu from "@shared/categoryMenu";
import PreviewAll from "@shared/previewAll";
import { Container } from "@util/standard";
import * as React from "react";
import Hero from "@shared/hero";
import { graphql } from "gatsby";
import { Query, SanityRecipeTagConnection } from "@graphql-types";
import RelatedRecipeCategories from "@components/recipes/relatedRecipeCollection";
import SearchBar from "@global/searchBar";
import { useStore } from "@state/store";

import { InstantSearch, connectHits } from "react-instantsearch-dom";
import AllergensFilter from "@shared/allergensFilter";
import { isBrowser, searchClient } from "@util/helper";
import SEO from "@shared/seo";
import Layout from "@shared/layout";
import { BreadcrumbPageContext } from "@util/types";
import { Breadcrumb } from "@global";
import { useCheckScreenWidth } from "@util/hooks";
interface QueryData extends Query {
  relatedSanityRecipeTag: SanityRecipeTagConnection;
}
interface Props {
  data: QueryData;
  pageContext: BreadcrumbPageContext;
}

const Hits = ({ hits }: any) => {
  return <PreviewAll algoliaRecipes={hits} iban={process.env.GATSBY_IBAN} />;
};

const CustomHits = connectHits(Hits);

const RecipesPage = (props: Props) => {
  if (!isBrowser()) return null;

  const { sanityRecipeLandingPage } = useRecipeLandingQuery();
  const { data, pageContext } = props;
  if (data == null) return null;

  const { isMobileWidth } = useCheckScreenWidth();

  const currentCategory = data.allSanityRecipeTag.nodes[0];
  const { searchParams, allergenFilter } = useStore();

  const filteredArray = currentCategory?.recipes?.filter(recipe =>
    recipe?.info?.allergens
      ?.map(allergen => allergen?.title)
      .includes(allergenFilter?.name)
  );

  React.useEffect(() => {
    useStore.setState({
      searchParams: {
        searchString: "",
        hasSearched: false,
        globalSearch: false,
      },
    });
  }, []);

  if (!currentCategory || !isBrowser()) return null
  return (
    <>
      <SEO seoData={currentCategory.seo} />
      <Layout>
        {currentCategory.main?.hero ? (
          <Hero sanityHero={currentCategory.main?.hero} />
        ) : (
          <Hero sanityHero={sanityRecipeLandingPage?.hero} />
        )}
        <Breadcrumb
          pageContext={pageContext}
          crumbLabel={currentCategory.main?.title ?? undefined}
        />
        <InstantSearch searchClient={searchClient} indexName="recipes">
          <Container backgroundColor="white" flexDirection="column">
            <Container
              height="auto"
              width="100%"
              margin="100px auto auto 100px"
              display="flex"
              flexDirection="row"
              mobileWidth="95%"
              mobileMargin="50px auto"
            >
              {isMobileWidth !== true && (
                <Container flexDirection="column">
                  <SearchBar />
                  <AllergensFilter />
                  <CategoryMenu
                    isRecipes
                    selectedCategory={currentCategory.main?.slug?.current}
                  />
                </Container>
              )}
              <Container margin="0px auto 0px 70px" mobileMargin="auto">
                {searchParams.hasSearched ? (
                  <CustomHits />
                ) : (
                  <PreviewAll
                    recipes={
                      allergenFilter?.name != null &&
                      allergenFilter?.name != "None"
                        ? filteredArray
                        : currentCategory.recipes
                    }
                    iban={pageContext.iban}
                  />
                )}
              </Container>
            </Container>
            <RelatedRecipeCategories
              relatedSanityRecipeTag={data.relatedSanityRecipeTag}
            />
          </Container>
        </InstantSearch>
      </Layout>
    </>
  );
};

export default RecipesPage;

export const query = graphql`
  query LandingPageQuery($slug: String, $iban: String) {
    allSanityRecipeTag(
      filter: {
        main: {
          slug: { current: { eq: $slug } }
          regions: { elemMatch: { iban: { eq: $iban } } }
        }
        recipes: {
          elemMatch: { regions: { elemMatch: { iban: { eq: $iban } } } }
        }
      }
    ) {
      nodes {
        _id
        seo {
          ...sanitySeo
        }
        main {
          title
          slug {
            current
          }
          regions {
            iban
          }
          hero {
            ...sanityHero
          }
        }
        recipes {
          _id
          id
          meta {
            ...sanitySeo
          }
          regions {
            ...sanityRegion
          }
          info {
            ...sanityRecipeInfo
          }
          ingredientsMethod {
            ...sanityIngredientMethodInfo
          }
        }
      }
    }
    relatedSanityRecipeTag: allSanityRecipeTag(limit: 3) {
      nodes {
        _key
        main {
          title
          slug {
            current
          }
          hero {
            ...sanityHero
          }
        }
      }
    }
  }
`;
